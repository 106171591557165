import { useRef, useEffect } from 'react';

import withComponentName from '../../../decorators/withComponentName';

import Styles from '../styles.module.scss';

const MeetupFormSection = () => {
  const formRef = useRef();

  useEffect(() => {
    let t;
    const a = formRef.current.getAttribute('data-pd-webforms');
    const i = `id${Math.random().toString(36).substring(7)}`;
    if (a) {
      formRef.current.setAttribute('data-script-id', i);
      t = document.createElement('script');
      t.src = `https://webforms.pipedrive.com/f/loader?t=${Math.random().toString(36).substring(7)}`;
      formRef.current.appendChild(t);
    }
  }, []);

  return (
    <div className={Styles['meetup-form-section']}>
      <div className={Styles.section}>
        <div className={Styles['flex-wrapper']}>
          <div
            className="pipedriveWebForms"
            data-pd-webforms="https://webforms.pipedrive.com/f/2TAdGVdiYW7sChnBp132wmuYzKXlLE9qt6r3zMgcndJxe7TJaStYpyE9lxoaJf03x"
            ref={formRef}
          />
        </div>
      </div>
    </div>
  );
};

export default withComponentName(MeetupFormSection);
